.deck-builder{
  display: block;
  position: relative;
  background-color: var(--bingo-background);
  padding: 20px;
}

.deck-builder-options {
  display: flex;
  flex-flow: column;
  position: relative;
  background-color: var(--app-background);
  padding: 20px;
}

.deck-builder-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.deck-builder-card {
  display: flex;
  height: 280px;
  width: 200px;
  background-color: var(--deck-editor-card-background);
  border-color: black;
  margin: 2px;
  border-radius: 20px;
  overflow: hidden;
  color: var(--deck-editor-card-text);
}

.deck-builder-card-final {
  height: 280px;
  width: 200px;
  margin: 2px;
}

.deck-builder-card-add-button {
  height: 100%;
  width: 100%;
}

.deck-builder-card-title {
  background-color: rgb(175, 175, 175);
  border-radius: 0 0 20px 20px;
  padding: 10px;
  height: 100px;
  cursor: text;
}

.deck-builder-card-title-box {
  position: relative;
  height: 90px;
  outline: 0;
}

.deck-builder-card-text {
  font-size: 14px;
  padding: 5px;
  height: 115px;
}

.deck-builder-card-text-box {
  position: relative;
  height: 115px;
  outline: 0;
}

.deck-builder-card-delete {
  background-color: rgb(119, 12, 12);
  color: white;
  height: 40px;
}

.deck-builder-input {
  width: 50%;
}

.deck-builder-label {
  font-weight: bold;
}

[data-theme='light'] {
  --bingo-background: white;
  --deck-editor-card-background: black;
  --deck-editor-card-text: white;
}

[data-theme='dark'] {
  --bingo-background: black;
  --deck-editor-card-background: white;
  --deck-editor-card-text: black;
}