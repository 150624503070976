.bingo{
  display: block;
  background-color: var(--bingo-background);
  padding: 5px;
  transition: all.5s;
  margin:auto;
}

[data-theme='light'] {
  --bingo-background: white;
}

[data-theme='dark'] {
  --bingo-background: black;
}

.bingo-board {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 10px;
  align-items: center;
  justify-content: center;
}