.victory {
  top:0;
  left:0;
  z-index: 4;
  position: absolute;
  font-size: 100px;
  font-weight: bold;
  text-align: center;
  background-color: var(--background);
  color: var(--text);
  pointer-events: none;

  width: 100%;
  height: 100%;
}

[data-theme='light'] {
  --background: rgba(126,126,126,.5);
  --text: black;
}

[data-theme='dark'] {
  --background: rgba(0,0,0,.5);
  --text: white;
}