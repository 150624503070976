
.tile{
  text-align: center;
  cursor: pointer;
  transform-style: preserve-3d;
  transition: var(--transition);
  width: 150px;
  height: 150px;
  word-wrap: break-word;
}
.tile:hover{
  transform: rotateY(180deg);
}

.tile-front{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
  overflow: hidden;

  border-width: 4px;

  color: var(--tile-text-front);
  background: var(--tile-background-front);

  border-style: solid;
  border-color: var(--tile-border);
}

.tile-back{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  backface-visibility: hidden;
  overflow: hidden;
  transform: rotateY(180deg);

  color: var(--tile-text-back);
  background: var(--tile-background-back);

  border-style: solid;
  border-color: var(--tile-border);

  word-wrap: break-word;
}

.tile-options-a{
  top: 0;
  left: 0;
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: var(--tile-background-tag-a);

  border-radius: 10px;
}
.tile-options-b{
  top: 0;
  left: 50%;
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: var(--tile-background-tag-b);

  border-radius: 10px;
}
.tile-options-c{
  top: 50%;
  left: 0;
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: var(--tile-background-tag-c);

  border-radius: 10px;
}
.tile-options-d{
  top: 50%;
  left: 50%;
  position: absolute;
  width: 50%;
  height: 50%;
  background-color: var(--tile-background-tag-d);

  border-radius: 10px;
}

.tile-overlay{
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--tile-overlay);
}

.tile{
  --tile-background-tag-a: blue;
  --tile-background-tag-b: green;
  --tile-background-tag-c: yellow;
  --tile-background-tag-d: red;
  --tile-background-a: rgb(0, 0, 255, .1);
  --tile-background-b: rgb(0, 124, 0, .1);
  --tile-background-c: rgba(255, 255, 0, 0.1);
  --tile-background-d: rgb(255, 0, 0, .1);
}

[data-theme='light'] {
  --tile-background-front: black;
  --tile-text-front: white;
  --tile-background-back: gray;
  --tile-text-back: white;

  --tile-border: none;
  --tile-background-selected: white;
  --tile-border-selected: black;
  --tile-text-selected: black;
}

[data-theme='dark'] {
  --tile-background-front: white;
  --tile-text-front: black;
  --tile-background-back: gray;
  --tile-text-back: black;

  --tile-border: none;
  --tile-background-selected: black;
  --tile-border-selected: white;
  --tile-text-selected: white;
}

[animation='on'] {
  --transition: all 0.8s;
}

[animation='off'] {
  --transition: 0;
}

.tile-selected{
  --tile-background-front: var(--tile-background-selected);
  --tile-border: var(--tile-border-selected);
  --tile-text-front: var(--tile-text-selected);
}
.tile-tag-a{
  --tile-border: var(--tile-background-tag-a);
  --tile-overlay: var(--tile-background-a);
}
.tile-tag-b{
  --tile-border: var(--tile-background-tag-b);
  --tile-overlay: var(--tile-background-b);
}
.tile-tag-c{
  --tile-border: var(--tile-background-tag-c);
  --tile-overlay: var(--tile-background-c);
}
.tile-tag-d{
  --tile-border: var(--tile-background-tag-d);
  --tile-overlay: var(--tile-background-d);
}