.toolbar {
  display: flex;
  flex-flow: column;
  position: relative;
  left: 0px;
  z-index: 1;
  padding: 20px;
  border-color: gray;
  background-color: rgba(62, 162, 255, 0.267);
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-radius: 0 0 20px 20px;
}

.toolbar-input {
  width: 50%;
}

.toolbar-label {
  font-weight: bold;
}

.toolbar-button{
  height: 100%;
  width: 100%;
}

[data-theme='light'] {
  --app-background: white;
  --app-text: black;
}

[data-theme='dark'] {
  --app-background: black;
  --app-text: white;
}