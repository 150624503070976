.app {
  display: grid;

  background-color: var(--app-background);
  color: var(--app-text);

  transition: all.5s;
  width: 100%;
  padding: 0;
  border: 0;
}

.app-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--app-background);
}

[data-theme='light'] {
  --app-background: white;
  --app-text: black;
}

[data-theme='dark'] {
  --app-background: black;
  --app-text: white;
}